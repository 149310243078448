import { useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
} from "@chakra-ui/react";

const Portal = ({
  title,
  children,
  manualSubmit,
  getValues,
  searchBy,
  isOpen,
  onClose,
}) => {
  const initialRef = useRef();

  return (
    <>
      <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box ref={initialRef}>{children}</Box>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="green"
              type="submit"
              onClick={() => {
                manualSubmit(
                  getValues(),
                  initialRef.current.children[0][0],
                  searchBy,
                  () => {
                    onClose();
                  }
                );
              }}
              mr={4}
            >
              Pretraži
            </Button>
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Odustani
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Portal;
