import { useState } from "react";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  FormErrorMessage,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import Portal from "./Portal";
import DatePicker from "./DatePicker";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { Search2Icon } from "@chakra-ui/icons";

const Inputs = ({ setSearchBy, setValues }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [date, setDate] = useState(new Date());
  const [from, setFrom] = useState(new Date());
  const [till, setTill] = useState(new Date());
  const [year, setYear] = useState(new Date());

  const { handleSubmit, errors, register, getValues, trigger } = useForm({
    shouldFocusError: true,
  });

  const manualSubmit = async (values, ref, searchBy, callback) => {
    const result = await trigger();

    // console.log(result)
    if (result) {
      if (values.datum) {
        values.start = dayjs(values.datum, "DD.MM.YYYY").add(5, "hours");
        values.finish = dayjs(values.datum, "DD.MM.YYYY").add(5, "hours");
      }
      if (values.start && values.finish) {
        values.start = dayjs(values.start, "DD.MM.YYYY").add(5, "hours");
        values.finish = dayjs(values.finish, "DD.MM.YYYY").add(5, "hours");
      }
      if (values.godina) {
        values.godina = Number(values.godina);
      }
      setSearchBy(searchBy.replace("1", ""));
      setValues({
        ...values,
        [searchBy.replace("1", "")]: searchBy.replace("1", ""),
      });
      callback();
    } else {
      //errors[Object.keys(errors)[0]].ref.focus();
      ref.focus();
    }
  };

  const submit = (values) => {
    if (values.datum) {
      values.start = dayjs(values.datum, "DD.MM.YYYY").add(5, "hours");
      values.finish = dayjs(values.datum, "DD.MM.YYYY").add(5, "hours");
    }
    if (values.start && values.finish) {
      values.start = dayjs(values.start, "DD.MM.YYYY").add(5, "hours");
      values.finish = dayjs(values.finish, "DD.MM.YYYY").add(5, "hours");
    }
    if (values.godina) {
      values.godina = Number(values.godina);
    }
    setValues({
      ...values,
      [selectedForm.replace("1", "")]: selectedForm.replace("1", ""),
    });
    setSearchBy(selectedForm.replace("1", ""));
    onClose();
  };

  const [selectedForm, setSelectedForm] = useState("");

  const inputs = {
    GetDatum1: {
      title: "Unos dana za pretragu",
      Component: (
        <>
          <FormControl
            id="datum"
            isInvalid={errors.datum && errors.datum.message}
          >
            <FormLabel>Datum od</FormLabel>
            <DatePicker
              autoFocus
              name="datum"
              format="DD.MM.YYYY"
              refd={register({ required: "Datum je obvezno polje" })}
              autoOk
              value={date}
              onChange={setDate}
            />
            <FormErrorMessage>
              {errors.datum && errors.datum.message}
            </FormErrorMessage>
            <FormHelperText></FormHelperText>
          </FormControl>
        </>
      ),
    },
    GetDatum: {
      title: "Unos perioda za pretragu",
      Component: (
        <>
          <FormControl
            id="start"
            isInvalid={errors.start && errors.start.message}
          >
            <FormLabel>Datum od</FormLabel>
            <DatePicker
              autoFocus={true}
              name="start"
              format="DD.MM.YYYY"
              refd={register({ required: "Datum od je obvezno polje" })}
              autoOk
              value={from}
              onChange={setFrom}
            />
            <FormErrorMessage>
              {errors.start && errors.start.message}
            </FormErrorMessage>
            <FormHelperText></FormHelperText>
          </FormControl>
          <FormControl
            mt={2}
            id="finish"
            isInvalid={errors.finish && errors.finish.message}
          >
            <FormLabel>Datum do</FormLabel>
            <DatePicker
              name="finish"
              format="DD.MM.YYYY"
              refd={register({ required: "Datum do je obvezno polje" })}
              autoOk
              value={till}
              onChange={setTill}
            />
            <FormErrorMessage>
              {errors.finish && errors.finish.message}
            </FormErrorMessage>
            <FormHelperText></FormHelperText>
          </FormControl>
        </>
      ),
    },
    GetGodina: {
      title: "Unos godine za pretragu",
      Component: (
        <FormControl
          mt={2}
          id="godina"
          isInvalid={errors.godina && errors.godina.message}
        >
          <FormLabel>Datum do</FormLabel>
          <DatePicker
            autoFocus={true}
            views={["year"]}
            format="YYYY"
            name="godina"
            refd={register({ required: "Obvezno polje" })}
            autoOk
            value={year}
            onChange={setYear}
          />
          <FormErrorMessage>
            {errors.godina && errors.godina.message}
          </FormErrorMessage>
        </FormControl>
      ),
    },
    GetHashTag: {
      title: "Unos tagova za pretragu",
      Component: (
        <FormControl id="hash" isInvalid={errors.hash && errors.hash.message}>
          <FormLabel>Tag</FormLabel>
          <Input
            autoFocus
            name="hash"
            ref={register({ required: "Tag je obvezno polje" })}
            type="text"
          />
          <FormErrorMessage>
            {errors.hash && errors.hash.message}
          </FormErrorMessage>
          <FormHelperText></FormHelperText>
        </FormControl>
      ),
    },
    GetKlasa: {
      title: "Unos klase za pretragu",
      Component: (
        <FormControl
          id="klasa"
          isInvalid={errors.klasa && errors.klasa.message}
        >
          <FormLabel>Klasa</FormLabel>
          <Input
            autoFocus
            name="klasa"
            ref={register({ required: "Klasa je obvezno polje" })}
            type="text"
          />
          <FormErrorMessage>
            {errors.klasa && errors.klasa.message}
          </FormErrorMessage>
          <FormHelperText></FormHelperText>
        </FormControl>
      ),
    },
    GetUrBroj: {
      title: "Unos UrBroja za pretragu",
      Component: (
        <FormControl
          id="urbroj"
          isInvalid={errors.urbroj && errors.urbroj.message}
        >
          <FormLabel>UrBroj</FormLabel>
          <Input
            autoFocus
            name="urbroj"
            ref={register({ required: "UrBroj je obvezno polje" })}
            type="text"
          />
          <FormErrorMessage>
            {errors.urbroj && errors.urbroj.message}
          </FormErrorMessage>
          <FormHelperText></FormHelperText>
        </FormControl>
      ),
    },
    GetNaslov: {
      title: "Unos naslova za pretragu",
      Component: (
        <FormControl
          id="naslov"
          isInvalid={errors.naslov && errors.naslov.message}
        >
          <FormLabel>Naslov</FormLabel>
          <Input
            autoFocus
            name="naslov"
            ref={register({ required: "Naslov je obvezno polje" })}
            type="text"
          />
          <FormErrorMessage>
            {errors.naslov && errors.naslov.message}
          </FormErrorMessage>
          <FormHelperText></FormHelperText>
        </FormControl>
      ),
    },
    GetSadrzaj: {
      title: "Unos sadržaja za pretragu",
      Component: (
        <FormControl
          id="sadrzaj"
          isInvalid={errors.sadrzaj && errors.sadrzaj.message}
        >
          <FormLabel>Sadržaj</FormLabel>
          <Input
            autoFocus
            name="sadrzaj"
            ref={register({ required: "Sadržaj je obvezno polje" })}
            type="text"
          />
          <FormErrorMessage>
            {errors.sadrzaj && errors.sadrzaj.message}
          </FormErrorMessage>
          <FormHelperText></FormHelperText>
        </FormControl>
      ),
    },
  };

  //maybe change the way Portal, Modal and Inputs work, so focus works on input
  return (
    <Box>
      <Box>
        <Flex flexWrap="wrap">
          <Box p={2} w={{ base: "100%", lg: "50%" }} mx="auto">
            <Box flexWrap="wrap" mx="auto" px={{ base: 4, lg: 0 }}>
              <Button
                leftIcon={<Search2Icon />}
                p={10}
                borderRadius="0"
                borderTopLeftRadius="md"
                borderTopRightRadius={{ base: "md", lg: "0" }}
                size="lg"
                w={{ base: "100%", lg: 1 / 3 }}
                type="button"
                onClick={() => {
                  setSelectedForm("GetSadrzaj");
                  onOpen();
                }}
              >
                Pretraži po sadržaju
              </Button>
              <Button
                leftIcon={<Search2Icon />}
                p={10}
                borderRadius="0"
                size="lg"
                w={{ base: "100%", lg: 1 / 3 }}
                type="button"
                onClick={() => {
                  setSelectedForm("GetHashTag");
                  onOpen();
                }}
              >
                Pretraži po tagu
              </Button>
              <Button
                leftIcon={<Search2Icon />}
                p={10}
                borderRadius="0"
                borderTopRightRadius={{ base: "0", lg: "md" }}
                size="lg"
                w={{ base: "100%", lg: 1 / 3 }}
                type="button"
                onClick={() => {
                  setSelectedForm("GetKlasa");
                  onOpen();
                }}
              >
                Pretraži po klasi
              </Button>

              <Button
                leftIcon={<Search2Icon />}
                p={10}
                borderRadius="0"
                borderBottomLeftRadius={{ base: "0", lg: "md" }}
                size="lg"
                w={{ base: "100%", lg: 1 / 3 }}
                type="button"
                onClick={() => {
                  setSelectedForm("GetUrBroj");
                  onOpen();
                }}
              >
                Pretraži po UrBroju
              </Button>
              <Button
                leftIcon={<Search2Icon />}
                p={10}
                borderRadius="0"
                size="lg"
                w={{ base: "100%", lg: 1 / 3 }}
                type="button"
                onClick={() => {
                  setSelectedForm("GetNaslov");
                  onOpen();
                }}
              >
                Pretraži po naslovu
              </Button>
              <Button
                leftIcon={<Search2Icon />}
                p={10}
                borderRadius="0"
                borderBottomRightRadius="md"
                borderBottomLeftRadius={{ base: "md", lg: 0 }}
                size="lg"
                w={{ base: "100%", lg: 1 / 3 }}
                type="button"
                onClick={() => {
                  setSelectedForm("GetGodina");
                  onOpen();
                }}
              >
                Pretraži po godini
              </Button>
            </Box>
          </Box>
        </Flex>
        {selectedForm.length > 0 ? (
          <Box m={2}>
            <Portal
              isOpen={isOpen}
              onClose={onClose}
              setValues={setValues}
              setSearchBy={setSearchBy}
              searchBy={selectedForm}
              manualSubmit={manualSubmit}
              getValues={getValues}
              title={inputs[selectedForm].title}
            >
              <Box as="form" onSubmit={handleSubmit(submit)}>
                {inputs[selectedForm].Component}
              </Box>
            </Portal>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default Inputs;
