import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Heading,
  Tag,
  Box,
  Text,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { decodeHtml } from "../helpers/functions";

const Review = ({ data, isOpen, onClose }) => {
  return (
    <>
      <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Pregled mišljenja</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box my={4}>
              {Object.keys(data.hashTag).map((key) => {
                return (
                  <Tag
                    key={key}
                    mr={2}
                    variant="subtle"
                    colorScheme="cyan"
                    as="a"
                    href={data.hashTag[key]}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {key}
                  </Tag>
                );
              })}
            </Box>

            <Box my={4}>
              <Heading fontWeight="normal" as="h3" fontSize="xl">
                Broj klase:{" "}
                <Box as="span" fontWeight="semibold">
                  {data.klasa}
                </Box>
              </Heading>
            </Box>
            <Box my={4}>
              <Heading fontWeight="normal" as="h3" fontSize="xl">
                Urudžbeni broj:{" "}
                <Box as="span" fontWeight="semibold">
                  {data.urBroj}
                </Box>
              </Heading>
            </Box>
            <Box my={4}>
              <Heading fontWeight="normal" as="h3" fontSize="xl">
                {data.mjesto}, {dayjs(data.datum).format("DD.MM.YYYY")}
              </Heading>
            </Box>
            <Heading>{data.naslov}</Heading>
            <Box mt={8} maxW="6xl" mx="auto">
              <Text whiteSpace="break-spaces" textAlign="justify">
                {decodeHtml(
                  data.sadrzaj.replaceAll("?", "").replaceAll(".  ", ". \n\n")
                )}
              </Text>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              as="a"
              href={data.url}
              target="_blank"
              rel="noreferrer noopener"
              colorScheme="teal"
              mr={3}
            >
              Otvori original
            </Button>
            <Button colorScheme="red" onClick={onClose}>
              Odustani
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Review;
