import { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Spacer,
  FormControl,
  FormLabel,
  Switch,
  useColorMode,
  useDisclosure,
  Stack,
  Skeleton,
} from "@chakra-ui/react";
import Inputs from "../components/Inputs";

import Table from "../components/Table";
import { handlePostRequest } from "../helpers/requests";
import { useQuery } from "react-query";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import Review from "../components/Review";

const Main = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchBy, setSearchBy] = useState("");
  const [values, setValues] = useState({});
  const [pickedValues, setPickedValues] = useState();

  //maybe delete [searchBy] from values
  const { data, isFetching } = useQuery(
    ["search", searchBy, values],
    () =>
      handlePostRequest(`https://pum.plc.hr/api/misljenja?q=${searchBy}`, [
        {
          user: "igor",
          pass: "7d2877e32452f5be89ac6ed3b1b2e4c2",
          ...values,
        },
      ])
        .then((res) => {
          return JSON.parse(res.data).sort((a, b) => {
            return new Date(b.datum) - new Date(a.datum);
          });
        })
        .catch((err) => console.log(err)),
    {
      enabled: values[searchBy] && values[searchBy].length > 0 ? true : false,
    }
  );

  return (
    <Box>
      <Box maxW="8xl" w="100%" mx="auto">
        <Flex mt={4} px={{ base: 2, lg: 0 }}>
          <Box>
            <Heading>PU - Mišljenja</Heading>
          </Box>
          <Spacer />
          <Box alignSelf="center">
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="color-mode" mb="0">
                <MoonIcon />
              </FormLabel>
              <Switch
                id="color-mode"
                onChange={toggleColorMode}
                isChecked={colorMode === "light" ? true : false}
              />
              <FormLabel htmlFor="color-mode" ml="3" mb="0">
                <SunIcon />
              </FormLabel>
            </FormControl>
          </Box>
        </Flex>
        <Box mt={10}>
          <Inputs setSearchBy={setSearchBy} setValues={setValues} />
        </Box>
        <Box mt={10} overflowX="auto">
          {!isFetching ? (
            data && (
              <Table
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                data={data}
                setPickedValues={setPickedValues}
              />
            )
          ) : (
            <Stack>
              <Skeleton height="35px" />
              <Skeleton height="35px" />
              <Skeleton height="35px" />
              <Skeleton height="35px" />
              <Skeleton height="35px" />
              <Skeleton height="35px" />
            </Stack>
          )}
        </Box>
      </Box>
      {pickedValues && (
        <Review
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          data={pickedValues}
        />
      )}
    </Box>
  );
};

export default Main;
