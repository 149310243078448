import { useMemo } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  chakra,
  Text,
  Box,
  Select,
  IconButton,
} from "@chakra-ui/react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from "@chakra-ui/icons";
import { useTable, useSortBy, usePagination } from "react-table";
import dayjs from "dayjs";

export default function DataTable({ data, onOpen, setPickedValues }) {
  const columns = useMemo(
    () => [
      {
        Header: "Pregled",
        accessor: "sadrzaj",
        disableSortBy: true,
        Cell: (props) => {
          return (
            <Button
              onClick={() => {
                setPickedValues(props.row.original);
                onOpen();
              }}
              loadingText="Preuzimanje"
            >
              Otvori
            </Button>
          );
        },
      },
      {
        Header: "Naslov",
        accessor: "naslov",
      },
      {
        Header: "Datum",
        accessor: "datum",
        Cell: (props) => {
          return <Text>{dayjs(props.value).format("DD.MM.YYYY")}</Text>;
        },
      },

      {
        Header: "ID",
        accessor: "ID",
      },
    ],
    [onOpen, setPickedValues]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination
  );

  return (
    <>
      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  isNumeric={column.isNumeric}
                >
                  {column.render("Header")}
                  <chakra.span pl="4">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <TriangleDownIcon aria-label="sorted descending" />
                      ) : (
                        <TriangleUpIcon aria-label="sorted ascending" />
                      )
                    ) : null}
                  </chakra.span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <Td
                    {...cell.getCellProps()}
                    isNumeric={cell.column.isNumeric}
                  >
                    {cell.render("Cell")}
                  </Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Box ml={2} alignItems="center" className="pagination">
        <Box mb={4} mt={4}>
          <IconButton
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            icon={<ChevronLeftIcon w={6} h={6} />}
          ></IconButton>{" "}
          <IconButton
            onClick={() => nextPage()}
            disabled={!canNextPage}
            icon={<ChevronRightIcon w={6} h={6} />}
          ></IconButton>{" "}
          <Box as="span" mx={4}>
            Stranica{" "}
            <strong>
              {pageIndex + 1} od {pageOptions.length}
            </strong>{" "}
          </Box>
        </Box>
        <Select
          mt={4}
          mb={8}
          w="150px"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Prikaži {pageSize}
            </option>
          ))}
        </Select>
      </Box>
    </>
  );
}
