import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "../theme";
import Main from "./Main";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <>
      <ChakraProvider theme={theme}>
        <Router>
          <Switch>
            <QueryClientProvider client={queryClient}>
              <Route path="/about">
                <div>test</div>
              </Route>

              <Route path="/">
                <Main />
              </Route>
            </QueryClientProvider>
          </Switch>
        </Router>
      </ChakraProvider>
    </>
  );
}

export default App;
